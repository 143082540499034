import React from "react";
import ReactDOM from "react-dom/client";
import "inticons/fonts/inticons.bundle.min.css";
import "inticons/fonts/inticons.untitled-ui.bundle.min.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import TagManager from "react-gtm-module";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import StyledToastContainer from "component/shared/StyledToastContainer";
import "react-toastify/dist/ReactToastify.css";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://2b0d3de374e14910be0c79b5bdacf3b7@o491558.ingest.sentry.io/5779872",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
  });
}

if (import.meta.env.VITE_APP_ARG === "prd") {
  const tagManagerArgs = {
    gtmId: "GTM-TQNF527",
  };
  TagManager.initialize(tagManagerArgs);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000,
      staleTime: 0,
      retry: 0,
    },
  },
});

const AppWrapper = () => {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          {!import.meta.env.PROD && <ReactQueryDevtools initialIsOpen={false} />}
          <App />
          <StyledToastContainer />
        </QueryClientProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

const router = createBrowserRouter([
  {
    path: "/*",
    element: <AppWrapper />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
