import Logo from "component/Logo";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isMarketOnlyUser, myPageUrl } from "utils/marketUtil";
import { cls } from "utils/util";
import AlarmHeader from "./AlarmHeader";
import { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { tokenState, headerColorState } from "state";
import StyledTooltip from "component/shared/StyledTooltip";
import { marketUrl } from "utils/util";
import { useGetCartItems } from "api/market/cart/hooks";
import { CHANNEL_TALK_ENABLED_ARTICLE_NUMBERS } from "@/constants";

const LogoAndSearchBar = ({ scrolled }: { scrolled: any }) => {
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();
  const location = useLocation();
  const headerColor = useRecoilValue(headerColorState);
  const user = token && token.user_type;
  const [searchKeyword, setSearchKeyword] = useState("");

  const { data: cartItems } = useGetCartItems({
    enabled: !!token,
  });

  const cartCount = useMemo(
    () => (cartItems?.items?.length || 0) + (cartItems?.book?.length || 0),
    [cartItems],
  );

  return (
    <div
      className={cls(
        scrolled ? "hidden" : "w-[1200px] mx-auto flex justify-between items-center h-[60px]",
      )}
    >
      <Link to="/">
        <Logo color={headerColor || ""} />
      </Link>
      <div className="flex items-center mt-3 gap-4 ">
        <form
          className="text-[14px] relative"
          onSubmit={(e) => {
            e.preventDefault();
            const q = searchKeyword.trim();
            navigate(`/search?indexUid=all&q=${q}`);
            setSearchKeyword("");
          }}
        >
          <i className="ii ii-search absolute left-[1px] px-2.5 h-full flex items-center leading-[24px] text-[24px] text-gray-800"></i>
          <input
            type="search"
            className="w-[320px] h-[40px] pl-[44px] py-2 rounded-md bg-gray-50 border border-solid border-secondary-200 placeholder-primary-800 focus:ring-1 focus:ring-primary-800 search-cancel:appearance-none focus:placeholder-opacity-0"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            placeholder="통합 검색창 입니다."
          />
          {searchKeyword && (
            <i
              className="ii ii-remove-circle h-full leading-[20px] text-[20px] text-gray-800 absolute top-0 right-[1px] cursor-pointer px-2.5 flex items-center"
              onClick={() => setSearchKeyword("")}
            ></i>
          )}
        </form>
        <div className="flex items-center gap-2">
          {/* MARK: 알림 아이콘 */}
          {user && !isMarketOnlyUser(user) && (
            <AlarmHeader>
              <i
                className={cls(
                  headerColor
                    ? `text-${headerColor} hover:bg-white/10`
                    : "text-gray-600 hover:bg-gray-100",
                  "ii ii-bell-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
                )}
              />
            </AlarmHeader>
          )}
          {/* MARK: 장바구니 아이콘 */}
          <Link className="relative" to={marketUrl("/market/cart")}>
            <i className="ii ii-cart-outline flex h-[40px] w-[40px] items-center justify-center rounded-full text-[28px] text-gray-600 hover:bg-gray-100"></i>
            {cartCount > 0 && (
              <span className="absolute right-1 top-1 flex h-[14px] w-[14px] items-center justify-center rounded-full bg-[#EA4E4E] text-[11px] text-white">
                {cartCount}
              </span>
            )}
          </Link>
          {/* MARK: 마이페이지 아이콘 */}
          <Link to={myPageUrl(token?.user_type)}>
            <i
              className={cls(
                headerColor
                  ? `text-${headerColor} hover:bg-white/10`
                  : "text-gray-600 hover:bg-gray-100",
                "ii ii-user-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
              )}
            />
          </Link>
        </div>
        {/* MARK: 편리한 재주문 / 대신 담아드려요 배너 */}
        {location.pathname === "/" && (
          <div className={cls("relative flex h-[40px] items-center")}>
            <Link
              to={marketUrl("/market/cart?scrollTo=savedCartList")}
              className={cls(
                "flex gap-[2px] rounded-l-[4px] bg-medi-bg-primarySolid px-[13px] py-[10px]",
                "hover:bg-medi-color-blueGray-900",
                "border-r border-medi-color-alphaWhite-30",
              )}
            >
              <img
                src="/img/promotion/ico-hand-pointer.svg"
                alt="편리한 재주문 아이콘"
                width="20"
                height="20"
              />
              <span className="text-[15px] font-semibold leading-[20px] tracking-[-0.33px] text-medi-text-white">
                편리한 재주문
              </span>
            </Link>
            <Link
              to={`/contents/article/${CHANNEL_TALK_ENABLED_ARTICLE_NUMBERS}`}
              className={cls(
                "group flex gap-[2px] rounded-r-[4px] bg-medi-bg-primarySolid px-[13px] py-[10px] relative",
                "hover:bg-medi-color-blueGray-900",
              )}
            >
              <img
                src="/img/promotion/ico-star.svg"
                alt="대신 담아드려요 아이콘"
                width="20"
                height="20"
              />
              <span className="text-[15px] font-semibold leading-[20px] tracking-[-0.33px] text-medi-text-white">
                대신 담아드려요
              </span>
              <StyledTooltip
                closeOnOutsideClick={false}
                tailJustify="end"
                className={cls(
                  "absolute left-[43px] top-[36px] flex items-center rounded-[3px] p-[8px] w-fit",
                  "bg-gradient-to-r from-[#714AD8] to-[#FD56E1]",
                  "after:!border-b-[6px] after:!border-l-[6px] after:!border-r-[6px] after:!border-t-[6px] after:!border-b-[#FD56E1]",
                  "transition-all duration-300 ease-in-out group-hover:top-[44px]",
                )}
              >
                <span className="text-[12px] font-medium leading-[18px] tracking-[-0.12px] text-medi-text-white whitespace-nowrap">
                  첫 구매시 모어덴이 대신 담아드립니다
                </span>
              </StyledTooltip>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogoAndSearchBar;
