import { CEZERIN } from "constants";
import dayjs from "dayjs";
import { marketUrl } from "./util";

export const isMarketOnlyUser = (userType) => {
  return userType === "biz" || userType === "sub";
};

export const money = (value) => {
  if (!value) return "0";
  const strValue = String(value);
  const isNegative = strValue.startsWith("-");
  return isNegative
    ? "-" + strValue.slice(1).replace(/(.)(?=(\d{3})+$)/g, "$1,")
    : strValue.replace(/(.)(?=(\d{3})+$)/g, "$1,");
};

export const salePercentage = (discountFactor) => {
  if (!discountFactor || discountFactor === 1) {
    return "";
  }
  const rawSalePercentage = (1 - discountFactor) * 100;
  const salePercentage = Math.round(rawSalePercentage);
  return `${salePercentage}%`;
};

export const phoneNumber = (value) => {
  if (value.length === 11) {
    // e.x. 010-1234-5678
    return value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  if (value.length === 8) {
    // e.x. 1234-1234
    return value.replace(/(\d{4})(\d{4})/, "$1-$2");
  }
  if (value.indexOf("02") === 0 && value.length === 9) {
    // e.x. 02-123-4567
    return value.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  if (value.indexOf("02") === 0 && value.length > 9) {
    // e.x. 02-1234-5678
    return value.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  if (value.length === 10) {
    // e.x. 031-123-4567
    return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  if (value.length > 10) {
    // e.x. 031-1234-5678
    return value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return value; // 아무 조건도 맞지 않으면 그대로 반환
};

export const companyNumber = (value) => {
  return value.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
};

export const getRemainDate = (expireDate) => {
  const expire = dayjs(expireDate);
  return expire.diff(dayjs(), "day");
};

export const isClassProduct = (products) => {
  // '클래스' 상품이 있는지 확인
  return products?.some(
    ({ category_id }) =>
      category_id === CEZERIN.CATEGORY_LECTURE_ID ||
      category_id === CEZERIN.CATEGORY_OFFLINE_LECTURE_ID,
  );
};

export const isSubUser = (user) => {
  return user?.userType === "sub";
};

export const isCashUsableUser = (user) => {
  // MARK: 모어덴 캐시가 사용 권한이 있는지 확인.
  // MARK: 보유하고 있는 모어덴 캐시 잔액과는 무관하며 해당 상태는 useHasCashUser()를 사용해서 파악해야 함.
  if (isSubUser(user)) {
    return user?.scopes?.useMoredencash;
  }
  return true;
};

/**
 *
 * @param {string[]} productGroupIds
 * @returns {boolean}
 */
export const isCashUsableProduct = (productGroupIds = []) => {
  const currentEnv = import.meta.env.VITE_APP_ARG ?? "local";
  const cashProductGroupIds = {
    prd: CEZERIN.CASH_MOREDEN_PRODUCT_GROUP_ID_PRD,
    stg: CEZERIN.CASH_MOREDEN_PRODUCT_GROUP_ID_STG,
    dev: CEZERIN.CASH_MOREDEN_PRODUCT_GROUP_ID_DEV,
    local: CEZERIN.CASH_MOREDEN_PRODUCT_GROUP_ID_DEV,
  };
  return productGroupIds.includes(cashProductGroupIds[currentEnv]);
};

export const isOfflineClass = (course) => {
  return course?.category?.slug === "offline";
};

export const isPreparationMedicineExists = (products) => {
  // '조제의뢰 버튼 노출' 부카테고리 적용여부 확인
  return products?.some(({ category_ids: categoryIds = [] }) => {
    return categoryIds.includes(CEZERIN.FUNCTIONAL_CATEGORY_EXPOSE_PREPARE_MEDICINE_BUTTON_ID);
  });
};

export const isReportMedicalSupplyItemExists = (products) => {
  // 병의원 사업자만 구매 가능한 상품 있는지 확인
  // 부 카테고리 '공급내역 신고 필요' 상품
  return products?.some(({ category_ids: categoryIds = [] }) => {
    return categoryIds.includes(CEZERIN.FUNCTIONAL_CATEGORY_REPORT_MEDICAL_SUPPLY_ID);
  });
};

export const isBusinessOnlyItemExists = (products) => {
  // 병의원 사업자만 구매 가능한 상품 확인
  // 부 카테고리 '사업자만 구매' 상품
  return products.some(({ category_ids: categoryIds = [] }) =>
    categoryIds.includes(CEZERIN.FUNCTIONAL_CATEGORY_BUSINESS_ONLY_ID),
  );
};

export const isCategoryLiqueurItemExists = (products) => {
  // 병의원 사업자만 구매 가능한 상품 확인
  // 주류 카테고리 속하는지 여부 확인
  return products.some(({ category_ids: categoryIds = [] }) =>
    categoryIds.includes(CEZERIN.FUNCTIONAL_CATEGORY_LIQUEUR_ID),
  );
};

export const isCategoryLiqueurItemsOnly = (products) => {
  // 주류 카테고리'만' 속하는지 여부 확인
  return (
    products &&
    products.every(
      (product) =>
        product.category_ids &&
        product.category_ids.includes(CEZERIN.FUNCTIONAL_CATEGORY_LIQUEUR_ID),
    )
  );
};

export const getProductPurchaseButtonType = (product) => {
  // MARK: type: "normal" | "groupPurchase" | "requestDeal" | "soldOut" | "preOrder"
  // MARK: groupPurchase(공동구매) 판단은 urlParam에서 "groupPurchaseId" 유무로 판단한다.
  if (!product) return null;

  const requestDealCondition = (product?.category_ids || []).includes(
    CEZERIN.FUNCTIONAL_CATEGORY_REQUESTDEAL_ID,
  );
  if (requestDealCondition) {
    return "requestDeal";
  }

  const soldOutCondition = product?.is_sold_out;
  if (soldOutCondition) {
    return "soldOut";
  }

  const preOrderCondition1 = !product?.is_sold_out && product?.stock_preorder;
  const preOrderCondition2 =
    !product?.is_sold_out && product?.enabled && product?.is_shipping_date_control;
  if (preOrderCondition1 || preOrderCondition2) {
    return "preOrder";
  }

  return "normal";
};

export const checkIsRequestDeal = (productCategoryIds = []) => {
  return productCategoryIds.includes(CEZERIN.FUNCTIONAL_CATEGORY_REQUESTDEAL_ID);
};

export const myPageUrl = (userType) => {
  if (isMarketOnlyUser(userType)) {
    return marketUrl("/mypage");
  }
  return "/mypage";
};
