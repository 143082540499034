import { CLASS_ROUTE, EDU_ROUTE } from "App";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { api, marketUrl } from "utils/util";
import { useMemo } from "react";

export default function useHeaderTabs() {
  const token = useRecoilValue(tokenState);
  const user = token && token.user_type;

  const { data: boardCategories } = useQuery(
    "boardCateCd",
    () => api?.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
      enabled: !!(user === "student" || user === "doctor"),
    },
  );

  const { data: adsTheater } = useQuery(
    `adsTheater`,
    () => api?.get("/advertisements/sponsor?advertisement=theater").then((res) => res.data),
    {
      onError: (e: any) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const boardList = boardCategories?.map((boardCategory: any) => {
    return { ...boardCategory, url: `/post?cate=${boardCategory.cate_cd}` };
  });

  const adsList = useMemo(() => {
    return adsTheater?.map((ads: any) => {
      const name = ads.title.includes("(주)") ? ads.title.split("(주)")[1] : ads.title;
      return { ...ads, cate_name: name, url: `/expo/${ads.name}` };
    });
  }, [adsTheater]);

  return useMemo(() => {
    const baseTabs = [
      {
        label: "클래스",
        value: "class",
        url: "/class",
        subTabs: [
          { cate_name: "클래스", value: "class", url: `/${CLASS_ROUTE}` },
          { cate_name: "보수교육", value: `edu`, url: `/${EDU_ROUTE}` },
        ],
      },
      {
        label: "마켓",
        value: "market",
        url: marketUrl(),
        subTabs: [],
      },
      {
        label: "콘텐츠",
        value: "contents",
        url: "/contents",
        subTabs: [],
      },
      {
        label: "게시판",
        value: "board",
        url: "/post",
        subTabs: token && boardList ? boardList : [],
      },
      {
        label: "Q&A",
        value: "qna",
        url: `/qna/${user === "student" ? "student" : "doctor"}`,
        subTabs: [
          { cate_name: "치과의사", value: "doctor", url: "/qna/doctor" },
          { cate_name: "치대생", value: "student", url: "/qna/student" },
          { cate_name: "AI 임상검색", value: "ai", url: "/ai" },
        ],
      },
      {
        label: "임상포럼",
        value: "forum",
        url: "/forum",
        subTabs: [],
      },
      {
        label: "개원/경영",
        value: "management",
        url: "/management",
        subTabs: [
          { cate_name: "컨설팅", value: "consulting", url: "/management/consulting" },
          { cate_name: "금융/대출", value: "loan", url: "/management/loan" },
          { cate_name: "기공소", value: "lab", url: "/management/lab" },
          { cate_name: "병원 양도/임대", value: "estate", url: "/management/estate" },
          { cate_name: "중고장터", value: "market", url: "/management/market" },
        ],
      },
      {
        label: "치크루팅",
        value: "chicruiting",
        url: "/chicruiting/company/people",
        subTabs: [],
      },
      {
        label: "구인구직",
        value: "recruit",
        url: "/recruit/doctor",
        subTabs: [],
      },
      {
        label: "세미나",
        value: "seminar",
        url: "/management/seminar",
        subTabs: [
          { cate_name: "세미나", value: "seminar", url: "/management/seminar" },
          { cate_name: "주간세미나", value: "seminar_weekly", url: "/management/seminar_weekly" },
        ],
      },
      adsList && {
        label: "상영관",
        value: "expo",
        url: "/expo",
        subTabs: adsList,
      },
      {
        label: "전치제",
        value: "festival",
        url: "/festival",
        subTabs: [],
      },
    ].filter(Boolean);

    return baseTabs;
  }, [boardList, adsList]);
}
