import { cartKeys } from "./queryKeys";
import { CartItems } from "types/cart";
import { getCartItems } from "./api";
import { useQuery, type UseQueryResult, type UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

export const useGetCartItems = <TSelected = CartItems>(
  queryOptions?: Omit<UseQueryOptions<CartItems, AxiosError, TSelected>, "queryFn" | "queryKey">,
): UseQueryResult<TSelected, AxiosError> => {
  return useQuery<CartItems, AxiosError, TSelected>({
    queryKey: cartKeys.cartItems(),
    queryFn: () => getCartItems(),
    ...queryOptions,
  });
};
