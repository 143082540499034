import { publicApi } from "utils/util";

export const getCartItems = async () => {
  try {
    const { data } = await publicApi.get("/market/cart/items");
    return data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
