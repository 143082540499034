import { useState, useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "../state";
import { useSearchSegemnet } from "hooks/useSearchSegment";
// import { amplitude } from "utils/analytics";

const PageTracker = () => {
  const { searchParams, trackSearchResult } = useSearchSegemnet();
  const { key, pathname, search } = useLocation();
  const [token] = useRecoilState(tokenState);
  const [prevPath, setPrevPath] = useState({
    path: "",
  });

  const segment = window.analytics;

  useEffect(() => {
    if (token && token.uid) {
      segment?.identify(token.uid, {
        type: token.user_type,
        name: token.user_name,
        userGroup: token.is_owner === 1 ? "owner" : token.user_type,
      });
      // amplitude는 pageView auto tracking
      // amplitude?.setUserId(token.uid);
    }
  }, [token]);

  useEffect(() => {
    trackSearchResult(pathname, prevPath);
    // Segment
    segment?.page(/* category */ "", /* name */ "", {
      // name: "",
      path: pathname,
      // referrer: "",
      search: search || undefined,
      title: document.title,
      url: window.location.href,
      // keywords: ""
    });

    setPrevPath({
      path: pathname,
      searchKeyword: searchParams.get("q"),
      indexUid: searchParams.get("indexUid"),
    });
    // Amplitude
  }, [key]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default PageTracker;
