import { ReactNode } from "react";
import { cls } from "../../utils/util";

interface StyledTooltipProps {
  closeBubble?: () => void;
  className?: string;
  children: ReactNode;
  tailPosition?: "top" | "bottom" | "left" | "right";
  tailJustify?: "start" | "center" | "end";
  closeOnOutsideClick?: boolean;
}

export default function StyledTooltip({
  closeBubble,
  className,
  children,
  tailPosition = "top", // top, bottom, left, right
  tailJustify = "center", // start, center, end
  closeOnOutsideClick = true,
}: StyledTooltipProps) {
  const bubblePosition = () => {
    if (tailPosition === "top") {
      return cls(
        "after:translate-x-[-50%] after:border-solid",
        "after:border-t-[0px] after:border-t-transparent",
        "after:border-l-[10px] after:border-l-transparent",
        "after:border-r-[10px] after:border-r-transparent",
        "after:border-b-[10px] after:border-b-gray-900",
      );
    }
    if (tailPosition === "bottom") {
      return cls(
        "after:translate-x-[-50%] after:border-solid",
        "after:border-t-[10px] after:border-t-gray-900",
        "after:border-l-[10px] after:border-l-transparent",
        "after:border-r-[10px] after:border-r-transparent",
        "after:border-b-[0px] after:border-b-transparent",
      );
    }
    if (tailPosition === "left") {
      return cls(
        "after:translate-y-[-50%] after:border-solid",
        "after:border-t-[10px] after:border-t-transparent",
        "after:border-l-[0px] after:border-l-transparent",
        "after:border-r-[10px] after:border-r-gray-900",
        "after:border-b-[10px] after:border-b-transparent",
      );
    }
    if (tailPosition === "right") {
      return cls(
        "after:translate-y-[-50%] after:border-solid",
        "after:border-t-[10px] after:border-t-transparent",
        "after:border-l-[10px] after:border-l-gray-900",
        "after:border-r-[0px] after:border-r-transparent",
        "after:border-b-[10px] after:border-b-transparent",
      );
    }
  };

  const bubbleJustify = () => {
    if (tailPosition === "top") {
      if (tailJustify === "start") {
        return "after:top-[-10px] after:left-[15%]";
      }
      if (tailJustify === "center") {
        return "after:top-[-10px] after:left-[50%]";
      }
      if (tailJustify === "end") {
        return "after:top-[-10px] after:left-[85%]";
      }
    }
    if (tailPosition === "bottom") {
      if (tailJustify === "start") {
        return "after:bottom-[-10px] after:left-[15%]";
      }
      if (tailJustify === "center") {
        return "after:bottom-[-10px] after:left-[50%]";
      }
      if (tailJustify === "end") {
        return "after:bottom-[-10px] after:left-[85%]";
      }
    }
    if (tailPosition === "left") {
      if (tailJustify === "start") {
        return "after:left-[-10px] after:top-[15%]";
      }
      if (tailJustify === "center") {
        return "after:left-[-10px] after:top-[50%]";
      }
      if (tailJustify === "end") {
        return "after:left-[-10px] after:top-[85%]";
      }
    }
    if (tailPosition === "right") {
      if (tailJustify === "start") {
        return "after:right-[-10px] after:top-[15%]";
      }
      if (tailJustify === "center") {
        return "after:right-[-10px] after:top-[50%]";
      }
      if (tailJustify === "end") {
        return "after:right-[-10px] after:top-[85%]";
      }
    }
  };

  return (
    <>
      <div
        className={cls(closeOnOutsideClick ? "fixed top-0 left-0 right-0 bottom-0 z-[9999]" : "")}
        onClick={(e) => {
          e.stopPropagation();
          closeBubble && closeBubble();
        }}
      ></div>
      <div
        className={cls(
          className ? className : "top-[10px] left-0 w-[310px]",
          "absolute left-[10px] bg-gray-900 text-white z-[510] rounded-md p-3 leading-6",
          "left-[10px] translate-x-[-50%]",
          "after:absolute after:content-['']",
          bubblePosition(),
          bubbleJustify(),
        )}
      >
        {children}
      </div>
    </>
  );
}
